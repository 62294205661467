import React from "react";
import Home from "../Components/Home/Home";

const LoginPage = () => {
  return (
    <div>
      <Home></Home>
    </div>
  );
};

export default LoginPage;
