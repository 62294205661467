import React from "react";
import ProfileComponent from "../Components/Profile/ProfileComponent";

function ProfilePage() {
  return (
    <div>
      <ProfileComponent/>
    </div>
  );
}

export default ProfilePage;
