import React from "react";
import Registration from "../Components/Registration/Registration";

function RegistrationPage() {
  return (
    <div>
      <Registration />
    </div>
  );
}

export default RegistrationPage;
