import React from "react";
import JobPortal from "../Components/JobPortal/JobDetails";

function JobPortalPage() {
  return (
    <div>
      <JobPortal />
    </div>
  );
}

export default JobPortalPage;
