import React from "react";
import Dashboard from "../Components/Dashboard/Dashboard";

const Dashboardpage = () => {
  return (
    <div>
      <Dashboard />
    </div>
  );
};

export default Dashboardpage;
